



































































































































import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator'

import { FormErrorsMixin, StructureConfigurable } from '../../../../support/mixins'

import {
  ADDRESS_FORM_FIELDSET_COMPONENT_KEY,
  AddressFormData,
  AddressFormFieldsetProps, defaultComponentConfig
} from './AddressFormFieldset.contracts'
import { AnyObject } from '@movecloser/front-core'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<AddressFormFieldset>({
  name: 'AddressFormFieldset',
  created () {
    this.model = this.formData
    this.model.country = ''

    this.config = this.getComponentConfig(ADDRESS_FORM_FIELDSET_COMPONENT_KEY, defaultComponentConfig)
  }
})
export class AddressFormFieldset extends Mixins(FormErrorsMixin, StructureConfigurable) {
  /**
   * List of all countries to deliver.
   */
  @Prop({ type: Array, required: true })
  public countries!: AddressFormFieldsetProps['countries']

  /**
   * @override
   */
  @Prop({ type: Object, required: false, default: null })
  public errors!: AddressFormFieldsetProps['_errors']

  /**
   * Wheteher billing address should be the same as shipping address.
   */
  @PropSync('sameBillingAddress', { type: Boolean, required: false, default: false })
  public isSameBillingAddress!: AddressFormFieldsetProps['sameBillingAddress']

  /**
   * Wheteher invoice form should be visible.
   */
  @PropSync('hasInvoice', { type: Boolean, required: false, default: false })
  public requestInvoice!: AddressFormFieldsetProps['hasInvoice']

  /**
   * Form's payload.
   */
  @Prop({ type: Object, required: true })
  protected readonly formData!: AddressFormData

  public model: AddressFormData = this.formData

  public get isCountrySelectEnable (): AnyObject {
    return this.getConfigProperty<AnyObject>('isCountrySelectEnable')
  }

  public get isPostalCodeRequired (): AnyObject {
    return this.getConfigProperty<AnyObject>('isPostalCodeRequired')
  }

  public get isShipmentLabelActive (): AnyObject {
    return this.getConfigProperty<AnyObject>('isShipmentLabelActive')
  }

  /**
   * Handles update of `addInvoice` model.
   */
  public onRequestInvoiceUpdate (value: boolean): void {
    if (!value) {
      const modelCopy = { ...this.model }
      delete modelCopy.invoice

      this.model = modelCopy
    } else {
      this.model = {
        ...this.model,
        invoice: {
          company: '',
          nip: ''
        }
      }
    }

    this.requestInvoice = value
    this.$emit('hasInvoice', value)
  }

  /**
   * Handles update of `model` in sameBilling Address checkbox.
   */
  public onSameBillingAddressUpdate (value: boolean): void {
    this.isSameBillingAddress = value
    this.$emit('sameBillingAddress', value)
  }

  @Watch('model', { deep: true })
  protected onModelChange (value: AddressFormData): void {
    this.$emit('update:formData', value)
    this.$emit('update:errors')
  }

  @Watch('formData')
  private updateFormData (): void {
    this.model = this.formData
  }
}

export default AddressFormFieldset
