






















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { ShippingCarrierCode, ShippingMethod, ShippingMethodCode } from '../../../../contexts'
import { Inject } from '../../../../support'

import { CheckoutServiceType, ICheckoutService } from '../../services/checkout'
import { CartModel, CheckoutStepCallback } from '../../contracts'

import { defaultDriver, driversRegistry } from './drivers/drivers'
import {
  isFreeShipmentThresholdReached,
  shippingIcon,
  supportedShippingMethods
} from './Shippings.helpers'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'Shippings' })
export class Shippings extends Vue {
  @Prop({ type: Array, required: true })
  protected readonly availableMethods!: string[]

  @PropSync('errors', { type: Array, required: false, default: () => ([]) })
  public _errors!: string[]

  @PropSync('selected', { type: Object, required: false, default: null })
  public _selected!: ShippingMethod | null

  @PropSync('callbacks', { type: Array, required: true })
  public _callbacks!: CheckoutStepCallback[]

  @Prop({ type: Object, required: true })
  protected readonly cart!: CartModel

  @Inject(CheckoutServiceType)
  protected readonly checkoutService!: ICheckoutService

  public get methods () {
    const considerFreeShipment: boolean =
      isFreeShipmentThresholdReached(this.cart.availableShippingMethods.map((method) => method.methodCode))
    return this.cart?.availableShippingMethods
      .filter(({ methodCode }) => this.availableMethods.includes(methodCode))
      .filter((method) => {
        return considerFreeShipment ? method.price.value === 0 : true
      })
      .map(({ methodCode, price }: ShippingMethod) => ({
        data: {
          carrierCode: methodCode,
          description: this.$t(`front.checkout.organisms.ShippingStep.method.${methodCode}.description`),
          image: shippingIcon[methodCode],
          price,
          title: this.$t(`front.checkout.organisms.ShippingStep.method.${methodCode}.title`)
        },
        driver: methodCode in driversRegistry ? driversRegistry[methodCode] : defaultDriver,
        id: methodCode
      }))
  }

  public get selectedShippingCode (): ShippingCarrierCode | null {
    return this._selected?.methodCode ?? null
  }

  public set selectedShippingCode (value: ShippingMethodCode | null) {
    this._selected =
      this.cart?.availableShippingMethods.find(method => method.methodCode === value) ?? null
    this._errors = []
  }
}
export default Shippings
